import { render, staticRenderFns } from "./Images.vue?vue&type=template&id=0fa3f3b8&lang=pug"
import script from "./Images.vue?vue&type=script&lang=ts"
export * from "./Images.vue?vue&type=script&lang=ts"
import style0 from "./Images.vue?vue&type=style&index=0&id=0fa3f3b8&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinner,QDialog,QStepper});
