import { render, staticRenderFns } from "./UploadButton.vue?vue&type=template&id=e458a344&scoped=true&lang=pug"
import script from "./UploadButton.vue?vue&type=script&lang=ts"
export * from "./UploadButton.vue?vue&type=script&lang=ts"
import style0 from "./UploadButton.vue?vue&type=style&index=0&id=e458a344&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e458a344",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn});
