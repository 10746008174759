
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { PartialDeep, Job, Booking } from '@ht-lib/accounts-models'
import { formatDate, onlineJobTypes, AimsCodeWithDescription, BookingSnap } from '@ht-lib/accounts-common'
import { yourBookingStore } from '../../bookings'
import { SelectorOptions } from '../../models/common'

@Component({
  name: 'JobType'
})
export default class extends Vue {
  @Prop({ type: Object, required: true }) bookingSnap!: BookingSnap
  @Prop({ type: String, required: true }) jobId!: string
  @Prop({ required: true, type: Object }) job!: Job

  formatDate = formatDate
  allJobTypes: AimsCodeWithDescription[] = []
  duplicateJobId: string | null = null
  filteredOptions: SelectorOptions[] = []

  get booking (): Booking {
    return this.bookingSnap.data()
  }

  @Emit('input')
  jobTypeUpdated (type: SelectorOptions): PartialDeep<Job> {
    const option = this.allJobTypes.find(x => x.id === type.value)
    return { jobType: type.value, accountCommissionWeb: option.commissionToAccount.webOrders, accountCommissionMod: option.commissionToAccount.modOrders }
  }

  get value (): SelectorOptions | null {
    return this.options.find(x => x.value === this.jobType) ?? null
  }

  changeJob (): void {
    this.$emit('changeJob', this.duplicateJobId)
  }

  get jobType (): string {
    return this.job.jobType
  }

  continueWithUpload (): void {
    this.duplicateJobId = null
    this.$emit('canContinue', true)
  }

  filterJobTypes (val: string, update: (arg0: () => void) => void): void {
    update(() => {
      this.filteredOptions = this.options.filter(x => x.label.toLowerCase().includes(val.toLowerCase()) || x.value.toLowerCase().includes(val.toLowerCase()))
    })
  }

  @Watch('job')
  @Watch('job.jobType')
  jobTypeChanged (): void {
    const book = yourBookingStore.bookingMap[this.bookingSnap.id]
    if (book == null) {
      this.$emit('canContinue', !!this.jobType)
      return
    }

    const match = book.jobs.find(x => x.data().jobType === this.jobType)
    if (match != null && match.id !== this.jobId) {
      this.duplicateJobId = match.id
      this.$emit('canContinue', false)
    } else {
      this.duplicateJobId = null
      this.$emit('canContinue', !!this.jobType)
    }
  }

  async created (): Promise<void> {
    this.jobTypeChanged()
    const jobTypes = await onlineJobTypes()
    this.allJobTypes = jobTypes.filter(x => x.market === this.job.market)
  }

  get options (): SelectorOptions[] {
    return this.allJobTypes
      .filter(jt => jt.isActive)
      .map(x => ({ value: x.id, label: x.description }))
  }
}
