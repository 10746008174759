
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { PhotographerRole, Job, JobPhotographerMap, PartialDeep } from '@ht-lib/accounts-models'
import { clone } from 'lodash'

@Component({
  name: 'Photographers',
  components: {}
})
export default class extends Vue {
  @Prop({ required: true }) job!: Job
  roles = Object.values(PhotographerRole)

  get clone (): Job {
    return clone(this.job)
  }

  roleUpdate (r: PhotographerRole, photographerId: string): void {
    this.validate({
      [photographerId]: {
        role: r
      }
    })
  }

  get photographers () {
    return Object.values(this.job.photographersMap).sort((a, b) => a.name.localeCompare(b.name))
  }

  @Emit('input')
  validate (photographersMap: PartialDeep<JobPhotographerMap>): PartialDeep<Job> {
    return { photographersMap }
  }
}
